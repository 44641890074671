import React from 'react'
import styled, { css } from 'styled-components'
import { DatePicker, DatePickerProps } from 'formik-material-ui-pickers'

import Select from 'src/components/inputs/Select'
import ArrowBack from '@material-ui/icons/KeyboardBackspace'

export const ArrowForward = styled(ArrowBack)`
  transform: rotate(180deg);
`

export const FormDatePicker = styled((props: DatePickerProps) => (
  <DatePicker views={['year', 'month', 'date']} openTo="year" {...props} />
))(
  ({ theme }) => css`
    margin-top: ${theme.spacing(0.75)}px;
  `
)

export const SequentialDatePicker = (props: DatePickerProps) => (
  <FormDatePicker views={['year', 'month', 'date']} openTo="year" {...props} />
)

export const FilterSelect = styled(Select)(
  ({ theme }) => css`
    font-weight: 500;
    margin: ${theme.spacing(0, 0.75)};
    color: ${theme.palette.background.paper};

    :hover {
      color: ${theme.palette.primary.main};
    }

    .MuiSelect-select.MuiSelect-select {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.MuiInputBase-root.Mui-disabled {
      color: ${theme.palette.background.grey};
      background-color: ${theme.palette.background.black};
    }

    .MuiSelect-icon.Mui-disabled {
      color: ${theme.palette.background.grey};
    }

    .MuiInput-underline::before {
      border-bottom-color: ${theme.palette.background.black};
    }

    ${theme.breakpoints.down('xs')} {
      font-size: ${theme.typography.pxToRem(12)};
    }
  `
)
