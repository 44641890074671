import React, { ChangeEvent, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Trans } from 'react-i18next'
import { Field, useFormikContext } from 'formik'

import i18n from 'i18next'
import Typography from '@material-ui/core/Typography'
import styled, { css } from 'styled-components'
import FormSelect from '../inputs/FormSelect'
import useRoundFilters from '../../hooks/useRoundFilters'
import { FormDatePicker } from '../common'
import CalendarIcon from '../../assets/svgComponents/CalendarIcon'
import { DateFormat, Languages, TimeFilter } from '../../utils/constants'

interface Props {}

const I18N_KEY = 'InsightGeneration'

const StyledFormSelect = styled(FormSelect)`
  margin-top: 6px;
`

const StyledGrid = styled(Grid)(
  ({ theme }) => css`
    padding: ${theme.spacing(2)}px;
  `
)

const StyledSubGrid = styled(Grid)(
  ({ theme }) => css`
    padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
  `
)

const RoundSelectionFilter: React.FC<Props> = () => {
  const { typeFilterOptions, timeFilterOptions } = useRoundFilters()
  const { setFieldValue } = useFormikContext()
  const [showCustomDateRange, setShowCustomDateRange] = useState(false)

  const handleTimeOptionChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    if (event.target.value === TimeFilter.Custom) {
      setShowCustomDateRange(true)
    } else {
      setShowCustomDateRange(false)
    }
    setFieldValue('range', event.target.value)
  }

  return (
    <Grid>
      <StyledGrid item xs={12} sm={6}>
        <Typography variant="caption">
          <Trans i18nKey={`${I18N_KEY}.rounds`}>Rounds</Trans>
        </Typography>
        <StyledFormSelect
          fullWidth
          options={timeFilterOptions}
          showErrorString={false}
          onChange={handleTimeOptionChange}
          name="range"
        />
      </StyledGrid>
      {showCustomDateRange ? (
        <StyledSubGrid item xs={12} sm={6}>
          <Typography variant="caption">
            <Trans i18nKey={`${I18N_KEY}.startDate`}>Start Date</Trans>
          </Typography>
          <Field
            autoOk
            fullWidth
            helperText=""
            name="customTimeRange.startDate"
            inputVariant="outlined"
            component={FormDatePicker}
            views={undefined}
            openTo={undefined}
            InputLabelProps={{ shrink: true }}
            InputProps={{ endAdornment: <CalendarIcon /> }}
            format={DateFormat[i18n.language as Languages].datePicker}
          />
          <Typography variant="caption">
            <Trans i18nKey={`${I18N_KEY}.endDate`}>End Date</Trans>
          </Typography>
          <Field
            autoOk
            fullWidth
            helperText=""
            name="customTimeRange.endDate"
            inputVariant="outlined"
            component={FormDatePicker}
            views={undefined}
            openTo={undefined}
            InputLabelProps={{ shrink: true }}
            InputProps={{ endAdornment: <CalendarIcon /> }}
            format={DateFormat[i18n.language as Languages].datePicker}
          />
        </StyledSubGrid>
      ) : (
        ''
      )}
      <StyledGrid item xs={12} sm={6}>
        <Typography variant="caption">
          <Trans i18nKey={`${I18N_KEY}.type`}>Types</Trans>
        </Typography>
        <StyledFormSelect
          fullWidth
          options={typeFilterOptions}
          showErrorString={false}
          name="type"
        />
      </StyledGrid>
    </Grid>
  )
}

export default RoundSelectionFilter
