import { ChangeEvent, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { CategoryType } from 'src/utils/golfConstants'
import { TimeFilter, TypeFilter } from 'src/utils/constants'
import {
  getSummary,
  updateFilters,
  summaryFiltersSelector,
} from 'src/store/summarySlice'

const typeFilterValues = Object.values(TypeFilter)
const timeFilterValues = Object.values(TimeFilter)

const useRoundFilters = (category: CategoryType = CategoryType.All) => {
  const { t } = useTranslation()
  const { typeFilter, timeFilter } = useSelector(summaryFiltersSelector)

  const typeFilterOptions = useMemo(
    () =>
      typeFilterValues.map(type => ({
        value: type,
        label: t(`Enums:TypeFilter.${type}`),
      })),
    [t]
  )

  const timeFilterOptions = useMemo(
    () =>
      timeFilterValues.map(time => ({
        value: time,
        label: t(`Enums:TimeFilter.${time}`),
      })),
    [t]
  )

  const dispatch = useDispatch()

  const handleTypeSelection = (event: ChangeEvent<{ value: unknown }>) => {
    const typeFilter = event.target.value as TypeFilter
    dispatch(updateFilters({ typeFilter }))
    dispatch(getSummary(category))
  }

  const handleTimeSelection = (event: ChangeEvent<{ value: unknown }>) => {
    const timeFilter = event.target.value as TimeFilter

    dispatch(updateFilters({ timeFilter }))
    // If custom is selected, fire dispatch once custom dates are selected
    if (timeFilter !== TimeFilter.Custom) {
      dispatch(getSummary(category))
    }
  }

  const getTimeFilterClick = (timeFilter: TimeFilter) => () => {
    dispatch(updateFilters({ timeFilter }))
    dispatch(getSummary(category))
  }

  return {
    typeFilter,
    timeFilter,
    getTimeFilterClick,
    handleTypeSelection,
    handleTimeSelection,
    typeFilterOptions,
    timeFilterOptions,
  }
}

export default useRoundFilters
