import React, { useState, useContext, useMemo } from 'react'
import styled, { css, StyledProps } from 'styled-components'

import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'

import {
  isNumber,
  handleNumberFormat,
  roundTo2DP,
  getMeasurementSymbol,
} from 'src/utils/helpers'
import { TranslationContext } from 'src/utils/TranslationContext'
import {
  MetricId,
  CategoryType,
  PerformanceIndicator,
  Measurement,
} from 'src/utils/golfConstants'
import { useTranslation } from 'react-i18next'
import useBenchmark from 'src/hooks/useBenchmark'
import NoData from './NoData'
import { TableCell, TitleCell } from 'src/components/dataDisplay/tableStyles'

const I18N_KEY = 'PerformanceIndicatorCategory'

interface Props {
  isOpen: boolean
  isSingleCategory: boolean
  isSingleRound: boolean
  category: string
  indicators: PerformanceIndicator[]
}

interface CollapseProps {
  open?: boolean
}

const StyledRow = styled(({ open, ...props }) => <TableRow {...props} />)(
  ({ theme, open = true }: StyledProps<CollapseProps>) => css`
    border-bottom: ${open ? '2px solid' : 'none'};
    border-color: ${theme.palette.background.xlight};
  `
)

const MoreIcon = styled(({ open, ...props }) => (
  <KeyboardArrowDownIcon {...props} />
))(
  ({ theme, open }: StyledProps<CollapseProps>) => css`
    transition: transform 0.2s ease-out;
    color: ${theme.palette.primary.main};
    font-size: ${theme.typography.pxToRem(20)};
    transform: rotate(${open ? '180deg' : '0'});
  `
)

const StyledTableCell = styled(TableCell)(
  ({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 0, 1, 1)};
    }
  `
)

const DataCell = styled(TableCell)(
  ({ theme }) => css`
    padding: 0;
    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 0, 1, 1)};
    }
  `
)

const PerformanceIndicatorCategory: React.FC<Props> = ({
  isOpen = false,
  isSingleCategory = false,
  isSingleRound = false,
  category,
  indicators,
}) => {
  const { t } = useTranslation()
  const { benchmarkName } = useBenchmark()

  const [open, setOpen] = useState(isOpen)
  const { metricLabels, measurementLabels, categoryLabels } =
    useContext(TranslationContext)!

  const columns = useMemo(
    () => [
      t(`${I18N_KEY}.performanceIndicators`, 'Performance Indicators'),
      isSingleRound
        ? t(`${I18N_KEY}.round`, 'This Round')
        : t(`${I18N_KEY}.rounds`, 'Rounds'),
      benchmarkName as string,
      t(`${I18N_KEY}.gap`, 'Gap'),
    ],
    [t, isSingleRound, benchmarkName]
  )

  const getGapValue = (value: number, benchmark: number, inverted: boolean) => {
    if (!benchmark && benchmark !== 0) {
      return { gap: 'N/A', isNegative: false }
    }
    const gap = roundTo2DP(value) - roundTo2DP(benchmark)

    // inverted: Lower the value -> Better the performance
    if (inverted) {
      return { gap: handleNumberFormat(gap, true), isNegative: gap > 0 }
    }

    return { gap: handleNumberFormat(gap, true), isNegative: gap < 0 }
  }

  const toggleOpen = () => {
    setOpen(!open)
  }

  const renderContent = () => (
    <>
      <TableRow>
        {columns.map(column => (
          <TableCell key={column}>
            <Typography variant="caption" color="textSecondary">
              {column}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
      {indicators.map(
        ({ value, metricId, inverted, benchmark, measurement }) => {
          const isParsPerRound = metricId === MetricId.ParsPerRound
          const isPercentage = measurement === Measurement.Percentage

          const hasValue = isNumber(value)
          const isBenchmarkValid = benchmark === 0 || isNumber(benchmark)
          const benchmarkValue = isPercentage ? benchmark * 100 : benchmark
          const playerValue = hasValue && isPercentage ? value * 100 : value
          const { gap, isNegative } = getGapValue(
            playerValue,
            benchmarkValue,
            inverted
          )
          const symbol = getMeasurementSymbol(
            measurement as Measurement,
            measurementLabels
          )

          return (
            <TableRow key={metricId}>
              <TitleCell>
                <Typography>{metricLabels[metricId]}</Typography>
              </TitleCell>
              <TableCell>
                <Typography>
                  {hasValue ? (
                    `${handleNumberFormat(playerValue)}${symbol}`
                  ) : (
                    <NoData />
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {isBenchmarkValid ? (
                    `${handleNumberFormat(benchmarkValue)}${symbol}`
                  ) : (
                    <NoData />
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                {hasValue && isBenchmarkValid ? (
                  isParsPerRound ? (
                    gap + symbol
                  ) : (
                    <Box
                      component="span"
                      color={isNegative ? 'error.main' : 'success.main'}
                    >
                      {gap}
                      {symbol}
                    </Box>
                  )
                ) : (
                  <NoData />
                )}
              </TableCell>
            </TableRow>
          )
        }
      )}
    </>
  )

  return (
    <>
      {indicators.length > 0 ? (
        <>
          {isSingleCategory ? (
            renderContent()
          ) : (
            <>
              <StyledRow open>
                <StyledTableCell component="th" scope="row">
                  <Typography variant="caption" color="textSecondary">
                    {category === CategoryType.All
                      ? 'General Statistics'
                      : categoryLabels[category]}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="right">
                  <IconButton
                    color="primary"
                    onClick={toggleOpen}
                    aria-label={`expand ${category} row`}
                  >
                    <MoreIcon open={open} />
                  </IconButton>
                </StyledTableCell>
              </StyledRow>
              <StyledRow open={open}>
                <DataCell colSpan={columns.length}>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Table>
                      <TableBody>{renderContent()}</TableBody>
                    </Table>
                  </Collapse>
                </DataCell>
              </StyledRow>
            </>
          )}
        </>
      ) : null}
    </>
  )
}

export default React.memo(PerformanceIndicatorCategory)
