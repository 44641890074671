import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'

import {
  getRoundTitle,
  getErrorStatus,
  authRequestFail,
  getPayloadFromResponse,
  isForbiddenOrUnauthorised,
  getSinglePayloadFromResponse,
} from 'src/utils/helpers'
import { api, Errors } from 'src/utils/api'
import { AppThunk } from 'src/store'
import { State } from 'typings/store'
import {
  CoachInvitations,
  CoachOverviewData,
  CoachPlayerStatus,
  CoachPlayerOverview,
  CoachInvitationItems,
  CoachPlayerOverviewItems,
} from 'src/models/coach-player-overview'
import { RootState } from 'src/store/rootReducer'
import { getBasicReducers } from 'src/utils/store'
import {
  TimeFilter,
  TypeFilter,
  COACH_PAGINATION_LIMIT,
} from 'src/utils/constants'
import { SummarySingleItem } from 'src/store/summarySlice'
import { CategoryType, MetricId, IO } from 'src/utils/golfConstants'

type InitialState = State<CoachOverviewData>
export interface PlayerSavedIOData extends IO {
  progress: number | undefined
  strokesGained: number | undefined | null
}

const initialState: InitialState = {
  data: {
    players: {},
    totalPlayers: 0,
    unregisteredPlayers: {},
  },
  error: '',
  loaded: false,
  requestInProgress: false,
}

const { actions, reducer } = createSlice({
  name: 'coachOverview',
  initialState,
  reducers: {
    ...getBasicReducers<CoachOverviewData>(initialState),
    updatePlayer: (state, action: PayloadAction<CoachPlayerOverview>) => {
      const uuid = action.payload.player.uuid
      const player = state.data!.players[uuid]

      state.data!.players[uuid] = { ...player, ...action.payload }
    },
    updatePlayers: (state, action: PayloadAction<CoachOverviewData>) => {
      state.data!.players = action.payload.players
      state.data!.totalPlayers = action.payload.totalPlayers
      state.data!.unregisteredPlayers = action.payload.unregisteredPlayers

      state.error = ''
      state.loaded = true
      state.requestInProgress = false
    },
  },
})

export default reducer
export const {
  reinitialise,
  requestError,
  updatePlayer,
  updatePlayers,
  requestFinished,
  requestInProgress,
} = actions

// Selectors
export const coachOverviewSelector = (state: RootState) => state.coachOverview

export const coachOverviewLoadingSelector = createSelector(
  coachOverviewSelector,
  ({ loaded, requestInProgress }) => ({
    loaded,
    requestInProgress,
  })
)

export const coachPlayersSelector = createSelector(
  coachOverviewSelector,
  ({ data }) => data!.players || {}
)

export const coachPlayersCount = createSelector(
  coachOverviewSelector,
  ({ data }) => data!.totalPlayers
)

export const coachPlayerUuidsSelector = createSelector(
  coachOverviewSelector,
  ({ data }) => Object.keys(data!.players || {})
)

export const unregisteredPlayersSelector = createSelector(
  coachOverviewSelector,
  ({ data }) => data?.unregisteredPlayers
)

export const coachPlayerOverviewSelector = (uuid: string) =>
  createSelector(coachOverviewSelector, ({ data }) => {
    const player = data!.players[uuid]
    const status = player?.status
    const rounds = player?.summary?.rounds
    const auxRounds = player?.summary?.auxRounds
    const categories = player?.summary?.totalSummary.categories
    const strokesGained = player?.summary?.totalSummary.strokesGained

    if (status !== CoachPlayerStatus.Accepted) {
      return {
        status,
        email: player?.email,
        player: player?.player,
        inviteUuid: player?.inviteUuid,
      }
    }

    const totalStrokesGained: SummarySingleItem = {
      data: [],
      sgTotal: strokesGained?.total.overall,
      progress: strokesGained?.total.progress,
      benchmark: strokesGained?.total.benchmark as number,
    }

    const io = player?.io?.savedMetrics.map(({ metricId, difference }) => {
      let sgValue
      let progress
      const data = { metricId, difference }

      if (metricId === MetricId.Drives) {
        sgValue = strokesGained?.drives.overall
        progress = strokesGained?.drives.progress
      } else {
        const category = categories?.find(item => metricId === item.metricId)

        progress = category?.progress
        sgValue = category?.strokesGained
      }

      const savedIOData: PlayerSavedIOData = {
        ...data,
        progress,
        strokesGained: sgValue,
      }

      return savedIOData
    })

    let totalValue = 0
    let roundCount = 0

    if (auxRounds) {
      auxRounds.forEach(round => {
        totalValue += round?.summary?.strokesGained.total.overall ?? 0
        roundCount += 1
      })
    }

    if (rounds && rounds.length) {
      rounds.forEach(round => {
        const roundTitle = getRoundTitle(round)
        const dateValue = new Date(round.datePlayed).getTime()

        totalValue += round?.summary?.strokesGained.total.overall ?? 0
        roundCount += 1

        totalStrokesGained.data.push({
          roundTitle,
          uuid: round.uuid,
          datePlayed: dateValue,
          value: round!.summary!.strokesGained.total.overall,
          average: round!.summary!.strokesGained.total.average,
          benchmark: round!.summary!.strokesGained.total.benchmark,
          rollingAverage: totalValue / roundCount,
        })
      })
    }

    return {
      io,
      status,
      email: player?.email,
      player: player?.player,
      timeline: player?.timeline,
      inviteUuid: player?.inviteUuid,
      totalStrokesGained: totalStrokesGained,
      benchmarkDetails: player?.summary?.benchmarkDetails,
      progressDonut: player?.summary?.totalSummary.progressDonut,
    }
  })

// Action Creators
export const getCoachPlayers =
  (start: number = 0, filter: string = ''): AppThunk =>
  async dispatch => {
    dispatch(requestInProgress())
    const options = filter ? `{filters:{filter:$ilike_${filter}}}` : undefined

    try {
      const response = await api.get('overview/player', {
        params: { count: true, start, limit: COACH_PAGINATION_LIMIT, options },
      })

      const coachInvitations: CoachInvitations[] =
        getPayloadFromResponse(response)
      const totalPlayers = response.data?.count || 0

      const players: CoachPlayerOverviewItems = {}
      const unregisteredPlayers: CoachInvitationItems = {}

      coachInvitations.forEach(
        ({ uuid, email, inviteUuid, firstName, lastName, accepted }) => {
          const item = { email, inviteUuid, firstName, lastName, accepted }

          if (uuid) {
            players[uuid] = item
          } else {
            unregisteredPlayers[email] = item
          }
        }
      )

      dispatch(updatePlayers({ players, totalPlayers, unregisteredPlayers }))
    } catch (error: any) {
      if (error.response?.status.toString() === Errors.NotFound) {
        dispatch(
          updatePlayers({
            players: {},
            totalPlayers: 0,
            unregisteredPlayers: {},
          })
        )
        return
      }
      dispatch(requestFinished())

      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      dispatch(requestError(getErrorStatus(error)))
    }
  }

export const getCoachPlayerByUuid =
  (uuid: string): AppThunk =>
  async dispatch => {
    try {
      const response = await api.get(`overview/player/${uuid}/overview`, {
        params: {
          type: TypeFilter.Both,
          time: TimeFilter.LastFive,
          category: CategoryType.All,
          suppress: true,
        },
      })

      const player: CoachPlayerOverview = getSinglePayloadFromResponse(response)
      dispatch(updatePlayer(player))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      dispatch(requestError(getErrorStatus(error)))
    }
  }

export const removePlayerCoachConnection =
  (invitationUuid: string): AppThunk =>
  async dispatch => {
    try {
      await api.post(`coach-player/remove`, { invitationUuid })
    } catch (error: any) {
      dispatch(requestFinished())

      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      dispatch(requestError(getErrorStatus(error)))
    }
  }
